//= ../../../bower_components/jquery/dist/jquery.min.js
//= ../../../bower_components/fullpage.js/vendors/scrolloverflow.min.js
//= ../../../bower_components/fullpage.js/dist/jquery.fullpage.min.js
//= ../../../bower_components/slick-carousel/slick/slick.min.js
//= ../../../bower_components/lightbox2/dist/js/lightbox.min.js
$(function(){
	$(document).ready(function() {
		$('a').on('click',function(){
			$(this).focusout();
		})
    $('#fullpage').addClass('init').fullpage({
    	scrollOverflow:true,
    	scrollOverflowOptions:{
    		interactiveScrollbars:true,
    		mouseWheelSpeed:10,
    		deceleration:0.0005,
    		fadeScrollbars:true,
    	},
    	afterRender: function(){
    		afterPageInit();
    		$.fn.fullpage.reBuild();
    	},
    	afterLoad:function(anchorLink, index){
    		var anchor = $('.section.active').data('anchor');
    		initNav(anchor);
    	},
    	afterResize:function(){
    		var anchor = $('.section.active').data('anchor');
    		initNav(anchor);
    	},
    	onLeave: function(index, nextIndex, direction){
    		var anchor = $('.section.active').data('anchor');
    		hideNavOnScroll(nextIndex);
    		initNav(anchor);
    		changeColorOfHeaders(nextIndex);
    	}
    });

    function changeColorOfHeaders(index) {
  		if($('.section').eq(index).hasClass('section_color_light')){
  			$('.footer').addClass('footer_type_dark');
  			$('.header__nav .nav-sm').addClass('nav-sm_type_dark');
  		}else{
  			$('.header__nav .nav-sm').removeClass('nav-sm_type_dark');
  			$('.footer').removeClass('footer_type_dark');
  		}
    }

    function hideNavOnScroll(index) {
  		if(index != 1){
  			$('.header__nav').fadeIn(400);
  			$('.header__socs').hide();
  		}else{
  			$('.header__nav').hide();
  			$('.header__socs').fadeIn(400);
  		}
    }

    function initNav(anchor) {
  		$('[data-anchor-href]').each(function(index, el) {
  			var href = $(this).data('anchor-href');
  			if(anchor == href){
  				$(this).parent().addClass('active');
  			}else{
  				$(this).parent().removeClass('active');
  			}
  		});
    }


    function afterPageInit() {
	    $('[data-anchor-href]').on('click',function(e){
	    	e.preventDefault();
	    	var anchorHref = $(this).data('anchor-href');
	    	$.fn.fullpage.moveTo(anchorHref, 0);
	    })

	    worksInit();
    }

    function worksInit() {
    	var worksWrap = $('.works');
    	var worksOpened = $('.works-slider');
    	var workPreview = worksWrap.find('.work');
    	var workLink = workPreview.find('.work__link');
    	var workOpen = worksOpened.find('.work-open');
    	var nextWorkBtn = $('[data-btn="next-work"]');
    	var backToPortfolioBtn = $('[data-btn="back-to-portfolio"]');

    	worksWrap.slick({
    		accessibility:false,
    		infinite:false,
    		fade:true,
    		arrows:false,
    		slide:'.works__step',
    		adaptiveHeight:true,
    		touchMove:false,
    		swipe:false,
    		speed:400,
    	})
    	worksOpened.slick({
    		fade:true,
    		accessibility:false,
    		arrows:false,
    		slide:'.work-open',
    		touchMove:false,
    		swipe:false,
    		speed:400,
    	})
    	worksWrap.on('afterChange',function(){
	    	setTimeout(function(){
					$.fn.fullpage.reBuild()
	    	},100)
    	})
    	worksOpened.on('afterChange',function(){
	    	setTimeout(function(){
					$.fn.fullpage.reBuild()
	    	},100)
    	})

    	workLink.on('click',function(e){
    		e.preventDefault();
    		var link = $(this);
    		var href = link.attr('href');
    		var index = worksOpened.find('.work-open'+href).data('slick-index');

    		worksOpened.slick('slickSetOption',{speed:0});
    		worksOpened.slick('slickGoTo',index);
    		setTimeout(function(){
    			goToStep2();
    			worksOpened.slick('slickSetOption',{speed:400});
    		},1)
    		link.blur();
    	})

    	nextWorkBtn.on('click',function(e){
    		e.preventDefault();
    		worksOpened.slick('slickNext');
    		goToStep2();
    		nextWorkBtn.blur();
    	})
    	backToPortfolioBtn.on('click',function(e){
    		e.preventDefault();
    		backToPortfolioBtn.blur();
    		goToStep1();
    	})

    	function goToStep2() {
    		worksWrap.slick('slickGoTo',1);
    	}
    	function goToStep1() {
    		worksWrap.slick('slickGoTo',0);
    	}
    }
	});